.map-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 60px;
  }
  
  .address-container {
    width: 60%; 
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    margin-top: 40px;
  }
  
  .address-box {
    font-size: 14px;
    line-height: 1.6;
  }

  #map {
    height: 300px;
    width: 100%;
    margin-right: 10px; 
  }

  .address-image {
    width: 100%; 
    height: auto; 
  }