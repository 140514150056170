.sign-up-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.sign-up-logo {
  position: absolute;
  top: 250px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  width: 270px;
  height: 50px;
  z-index: 1; 
}

.sign-up-logo img {
  max-width: 160%;
  height: auto;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 20px;
  position: relative;
  z-index: 2;
  margin-top: -200px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  color: white;
}

.signup-form h1 {
  margin-bottom: 20px;
  font-size: 24px;
  text-shadow: 2px 2px 4px black;
}

.signup-form input {
  margin-bottom: 10px;
  padding: 10px;
  width: 300px; 
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signup-form button {
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #0056b3;
}
