html {
  height: 100%;
  background: url('/assets/beer.jpeg') no-repeat center fixed;
  background-size: cover;
  box-sizing: border-box;
}

* {
  font-family: 'Insanibc', sans-serif;
}


.inicio-container {
  text-align: center;
  margin-top: 50px; 
}

/* Estilos para el logo */
.logo {
  width: 350px; 
  height: auto; 
  margin-bottom: 60px; 
}





/* Floating button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 93px;
  background-color: black;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 
              0px 0px 10px white; /* Destello blanco al hacer hover */
  z-index: 2;

  animation: pulse 2s infinite;
}

.floating-button:hover {
  background-color: #0056b3;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}





.black-section {
  background-color: black;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px; 
  margin-bottom: 30px; 
}

.black-section h3 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(252, 195, 50);
}

.left-section,
.right-section {
  flex: 1;
}

/* Estilos para el texto en la nueva sección */
.black-section p {
  font-size: 16px;
}

/* Estilos para la sección de la izquierda */
.left-section {
  margin-right: 20px; 
}





/* Carrusel de foto */
.carousel-container {
  margin-top: 90px; 
  max-width: 600px; 
  margin: 0 auto;
}

.carousel .slide img {
  max-height: 500px; 
  object-fit: contain; 
}


