
.logo-container {
  position: absolute;
  top: 10px; 
  right: 20px; 
}

.logo-image {
  width: 100px; 
  height: auto; 
}


  .wave-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.8; 
    filter: brightness(110%); 
  }
  
  .menu {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
  }
  

  
  /* Estilos para los botones de cambio de idioma */
  .menu li button {
    margin-left: 20px;
    cursor: pointer;
  }


  /* estilo header curvo */
.custom-shape-divider-top-1690924419 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: -1; 
}
  
.custom-shape-divider-top-1690924419 svg {
  position: relative;
  display: block;
  width: calc(174% + 1.3px);
  height: 157px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1690924419 .shape-fill {
  fill: #FFFFFF;
}
  

.navigationBar {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.nav-container {
  height: 80px; 
  position: sticky;
  top: 0;
  z-index: 100; 
}


  /* Language buttons */
  .language-buttons {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2000;
  }
  
.button-52 {
  font-size: 16px; 
  font-family: 'revert';
  font-weight: bold;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 8px 15px; 
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  color: black; 
}
  
.button-52:after {
  content: "";
  background-color: goldenrod;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}
  
.button-52:hover:after {
  top: 0px;
  left: 0px;
}





.bm-burger-button {
  position: relative;
  width: 33px;
  height: 25px;
  top: 5px;
  margin-left: -40px;
  background: transparent;
  border: black;
  z-index: 20;
}

.bm-burger-button-transparent {
  position: absolute;
  width: 60px; 
  height: 85px; 
  top: 20px;
  left: -53px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1000;
}
  
  /* Estilos para el contenedor del menú */
.menu-container {
  position: fixed;
  top: 10px; 
  right: 20px; 
  z-index: 15; 
  cursor: pointer;
  justify-content: flex-start;
  width: 80%;
  z-index: 1000;
}

.bm-burger-bars {
  background: #333; 
  z-index: 20;
}



.bm-menu a {
  display: block;
  padding: 40px 45px 10px;  
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s ease-in-out;
}

.bm-menu a:hover {
    color: #007bff; 
}

.bm-menu {
  background-color: rgba(0, 0, 0, 0.8); 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}





