
.banner {
  text-align: center; 
  margin-bottom: 20px; 
  position: relative; 
  width: 100%; 
  max-height: 300px; 
  overflow: hidden; 
}
  
.banner img {
  width: 100%; 
  object-fit: cover; 
}

.encuesta-text {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  background-color: white; 
  padding: 10px 20px; 
  font-size: 60px; 
  font-weight: bold; 
  color: black; 
  border-radius: 5px; 
  font-family: 'Insanibc', sans-serif
}

.encuesta-content-container p {
  max-width: 370px; /* ancho máximo */
  word-wrap: break-word;
}


  .encuesta-content-container {
    margin-left: 20px; 
  }




/* Boton Back*/
.button_back {
  --primary-color: #000;
  --secondary-color: #fff;
  --border-color: #000;
  background-color: var(--primary-color);
  border: 2px solid var(--border-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.button_back::before {
  content: "";
  display: block;
  position: absolute;
  top: -100%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: var(--secondary-color);
  border-radius: 50%;
  transform: translate(-50%, 0) scale(0);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.button_back:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.button_back:hover::before {
  transform: translate(-50%, -50%) scale(2);
}

.button_back:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* Boton Next*/
.button_next {
  --primary-color: #000;
  --secondary-color: #fff;
  --border-color: #000;
  background-color: var(--primary-color);
  border: 2px solid var(--border-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;

  margin-left: 10px;
}

.button_next::before {
  content: "";
  display: block;
  position: absolute;
  top: -100%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: var(--secondary-color);
  border-radius: 50%;
  transform: translate(-50%, 0) scale(0);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.button_next:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.button_next:hover::before {
  transform: translate(-50%, -50%) scale(2);
}

.button_next:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


/* Separar boton back de next */
.button-container {
  display: flex;
  gap: 10px; 
  margin-top: 10px; 
}


/* Boton SENT */
.button_send {
  --primary-color: rgba(227, 220, 220, 0.722);
  --secondary-color: green;
  --border-color: #000;
  background-color: var(--primary-color);
  border: 2px solid var(--border-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s ease-in-out;

  margin-left: 10px;
}

.button_send::before {
  content: "";
  display: block;
  position: absolute;
  top: -100%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: var(--secondary-color);
  border-radius: 50%;
  transform: translate(-50%, 0) scale(0);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.button_send:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
}

.button_send:hover::before {
  transform: translate(-50%, -50%) scale(2);
}

.button_send:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}


/* Boton SEND */
.button-restart {
  padding: 0.5rem 2.5rem;
  box-shadow: 0px 0px 0px 3px black;
  border: 0;
  border-radius: 0.5rem;
  /* font-size: 1rem; */
  background-color: #ffc83d;
  color: black;
  font-weight: bolder;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  position: relative;
  transition: all .9s ease-in-out;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
}

.button-restart:hover {
  background-color: #f03a17;
  color: white;
}

.button-restart::before {
  content: "🍺";
  position: absolute;
  left: -0rem;
  top: 0rem;
  opacity: 1;
  font-size: 1.55rem;
  transition: all .9s ease-in-out;
  transform: rotate(0deg);
}

.button-restart:hover::before {
  content: "🇲🇽";
  opacity: 1;
  visibility: visible;
  transform: rotate(40deg);
  font-size: 2.5rem;
  top: -0.75rem;
  transition: all .9s ease-in-out;
  animation: rightRun 2s forwards;
}

.button-restart:active::before {
  content: "🌵";
}

@keyframes rightRun {
  100% {
    transform: translateX(140px);
  }
}