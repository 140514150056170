.favoritos-text {
  position: absolute;
  top: 141px; 
  left: 83px; 
  background-color: white; 
  padding: 10px 20px; 
  font-size: 30px; 
  font-weight: bold; 
  color: black; 
  border-radius: 5px; 
  font-family: 'Insanibc', sans-serif;
  position: fixed;
  z-index: 1; 
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

/* Estilo para el mensaje dentro del overlay */
.overlay p {
  color: white;
  text-align: center;
  font-size: 24px;
  margin: 0;
  padding: 30px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.overlay a {
  color: #007bff; 
  text-decoration: underline; 
}

.no-scroll {
  overflow: hidden;
}


.favoritos-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.favoritos-item {
  /* Estilos para cada cerveza en la lista */
  border: 1px solid #ddd;
  padding: 10px;
  margin: 25px 0;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  max-width: 220px; /* largo horizontal */
}

.favoritos-item button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px; 
}








.favoritos-container {
  display: flex;
  flex-direction: column;
}


.auth-details-container,
.favoritos-list-container {
  margin: 20px auto; 
}

.favoritos-content {
  flex: 1;
}


.favoritos-list-container {
  margin-top: 200px; 
  margin-left: 60px; 
}



.auth-details-container {
  position: absolute;
  top: 53px;
  right: 110px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  z-index: 3;
}

.user-welcome {
  text-align: right;
}

.user-email {
  margin: 0;
  font-weight: bold;
}

.sign-out-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 5px;
  border-radius: 3px;
}