.sign-up-page {
    background-color: rgb(252, 195, 50);
    background-attachment: fixed;
    background-size: 100% auto; 
    background-repeat: no-repeat;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
}