.sign-in-text {
  position: absolute;
  top: 150px; 
  left: 50%; 
  transform: translateX(-50%);
  background-color: white;
  padding: 10px 20px;
  font-size: 26px;
  font-weight: bold;
  color: black;
  border-radius: 5px;
  font-family: 'Insanibc', sans-serif;
  width: 270px;
  height: 50px;
  display: flex; 
  align-items: center; 
  justify-content: center;
}
  
  
  .sign-in-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px); 
    margin-top: 50px; 
  }
  
  .sign-in-logo img {
    max-width: 100%; 
    height: auto;
  }
  

  .sign-in-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; 
  }
  

  
  .sign-in-form .input-group {
    margin-bottom: 20px;
  }
  
  .sign-in-form label {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .sign-in-form input {
    width: 80%;
    padding: 10px;
    font-size: 12px;
  }
  
  .sign-in-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .sign-in-form button:hover {
    background-color: #0056b3;
  }


.login-message {
  color: white;
  text-align: center;
  font-size: 18px;
  z-index: 3; 
  font-size: 24px; 
  padding: 30px; 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

.login-message a {
  color: #007bff; 
  text-decoration: underline; 
}

.sign-in-form {
  display: flex;
  flex-direction: column;
  margin-left: 50px; 
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  margin-top: 70px;
}
