.acercade-container {
    text-align: center;
    padding: 50px 0;
  }
  
  /* Títulos */
  .acercade-title {
    font-size: 34px;
    color: black;
    margin-bottom: 20px;
    background-color: white;
    padding: 20px 25px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 20px;
  }

  
  /* Texto principal */
  .acercade-text {
    font-size: 18px;
    color: black;
    margin-bottom: 30px;
  }
  
  /* Sección de fundadoras */
  .founders-section {
    background-color: black;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Imagen de las fundadoras */
  .founders-image {
    max-width: 100%;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  /* Nombre de las fundadoras */
  .founder-name {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
  }
  
  /* Descripción de las fundadoras */
  .founder-description {
    font-size: 16px;
    color: whitesmoke
  }
  
  /* Pasión */
  .passion-section {
    margin-top: 40px;
  }
  
