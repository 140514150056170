.nuestros-productos-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .product-description p {
    color: white;  
    text-align: left; 
    font-size: 16px; 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .product-title {
    color:rgb(252, 195, 50);  
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    font-family:fantasy;
    font-size: 24px;
  }
  
  .product-slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
  }
  
  .product-image img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-description {
    flex: 1;
    padding: 0 20px;
    text-align: left;;
  }
  
  .carousel-root {
    width: 100%;
  }
  
  .carousel.carousel-slider {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  
  .carousel .slider-wrapper.axis-horizontal .slider {
    display: flex;
  }
  
  .carousel .slider {
    margin: 0;
    padding: 0;
    display: flex;
    transition: all 0.5s;
  }
  
  .carousel .slider-slide {
    min-width: 100%;
    flex: 0 0 auto;
    height: auto;
    position: relative;
  }
  
  .carousel .control-arrow {
    font-size: 24px;
    color: #333;
    background: transparent;
  }
  
  .carousel .control-arrow:hover {
    color: #000;
  }
  
  .carousel .thumbs-wrapper {
    display: none;
  }
  
  /* Media query for mobile responsiveness */
  @media (max-width: 768px) {
    .product-slide {
      flex-direction: column;
      text-align: center;
    }
  
    .product-image img {
      margin-bottom: 20px;
    }
  
    .product-description {
      padding: 0;
    }
  }
  
  